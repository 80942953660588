import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Typography from "@material-ui/core/Typography"
import {
  CircularProgress,
  FormControl,
  Grid,
  makeStyles,
  Paper,
} from "@material-ui/core"

import { Button } from "../ui/Button"
import { UserAddress } from "../../ts/interfaces"
import { FormContextType, useForm } from "../../contexts/formContext"
import { Address } from "../ui/Address"
import { useApiMutation } from "../../hooks/useApiMutation"
import { PaymentIntentResponse } from "../../ts/interfaces"
import {
  billingFields,
  stripeFields,
} from "../../checkout/shared/checkoutFormFields"
import { VAT_REQUIRED_COUNTRIES } from "../../checkout/components/steps/CheckoutShippingAddress"
import { GetGiftOrderToConfirmAddressData } from "../../pages/redeem"
import { FormInput } from "../ui/Input"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;
`

const AddressWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
`

const NameText = styled.p`
  font-weight: 600;
`

const ButtonsWrapper = styled.div`
  text-align: center;
  margin: 2rem;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
`

const PayAndConfirmWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    borderRadius: 15,
  },
}))

const ConfirmGiftAddress = ({
  getGiftOrderToConfirmAddressData,
}: {
  getGiftOrderToConfirmAddressData: GetGiftOrderToConfirmAddressData
}) => {
  const classes = useStyles()
  const [havBilling, setHaveBilling] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const { formState, formActions } = useForm() as FormContextType

  const shippingAddress: UserAddress =
    getGiftOrderToConfirmAddressData &&
    getGiftOrderToConfirmAddressData?.GetGiftOrderToConfirmAddress
      ?.shippingAddress[0]

  const [isEditAddressMode, setisEditAddressMode] = useState<boolean>(false)
  const [forceEditAddressMode, setForceEditAddressMode] = useState<boolean>(
    false
  )

  const [selectedAddress, setSelectedAddress] = useState<Partial<UserAddress>>({
    id: shippingAddress?.id || "",
    firstName: shippingAddress?.firstName || "",
    lastName: shippingAddress?.lastName || "",
    city: shippingAddress?.city || "",
    country:
      shippingAddress?.country
        ?.split(" ")
        .map((c: string) => c.charAt(0).toUpperCase() + c.slice(1))
        .join(" ") || "",
    postal: shippingAddress?.postal || "",
    province: shippingAddress?.province || "",
    street: shippingAddress?.street || "",
    unit: shippingAddress?.unit || "",
    phone: shippingAddress?.phone || "",
    vat: shippingAddress?.vat || "",
  })

  useEffect(() => {
    if (
      getGiftOrderToConfirmAddressData?.GetGiftOrderToConfirmAddress?.orderId
    ) {
      setSelectedAddress(
        getGiftOrderToConfirmAddressData.GetGiftOrderToConfirmAddress
          .shippingAddress[0]
      )
    }
  }, [getGiftOrderToConfirmAddressData])

  useEffect(() => {
    if (selectedAddress.id) {
      let data: any = {
        firstName: selectedAddress.firstName,
        lastName: selectedAddress.lastName,
        email:
          getGiftOrderToConfirmAddressData?.GetGiftOrderToConfirmAddress
            ?.giftEmail,
      }
      ;[
        "FirstName",
        "LastName",
        "Street",
        "Unit",
        "City",
        "Country",
        "Postal",
        "Phone",
        "Province",
        "Vat",
      ].forEach(field => {
        const val = (selectedAddress as any)[
          field[0].toLowerCase() + field.substring(1)
        ]
        if (val) {
          data["shipping" + field] = val
        }
      })
      formActions.setFormData(data)
      if (
        !selectedAddress.country ||
        (VAT_REQUIRED_COUNTRIES.includes(selectedAddress.country) &&
          !selectedAddress.vat)
      ) {
        setForceEditAddressMode(true)
      }
    }
  }, [selectedAddress])

  const [success, setSuccess] = useState<boolean>(false)

  useEffect(() => {
    formActions.setSkipValidationFunction((field: string): boolean => {
      return [...stripeFields, ...billingFields].includes(field)
    })
  }, [])

  const [
    updateAndConfirmGiftShippingAddress,
    {
      loading: updateAndConfirmGiftShippingAddressLoading,
      error: updateAndConfirmGiftShippingAddressError,
      data: updateAndConfirmGiftShippingAddressData,
      errMsg: updateAndConfirmGiftShippingAddressErrMsg,
    },
  ] = useApiMutation("billing", "UpdateAndConfirmGiftShippingAddress")

  useEffect(() => {
    if (updateAndConfirmGiftShippingAddressError) {
      setErrorMsg(
        "Sorry there was an error. Please try again or contact our customer support team."
      )
    }
  }, [updateAndConfirmGiftShippingAddressError])

  const handleConfirm = () => {
    let addressData = {
      firstName: selectedAddress.firstName,
      lastName: selectedAddress.lastName,
      unit: selectedAddress.unit,
      city: selectedAddress.city,
      country: selectedAddress.country,
      postal: selectedAddress.postal,
      province: selectedAddress.province,
      street: selectedAddress.street,
      phone: selectedAddress.phone,
      vat: selectedAddress.vat,
    }

    if (isEditAddressMode || forceEditAddressMode) {
      if (!formActions.isValid()) {
        return
      }
      addressData = {
        firstName: formState.inputs.shippingFirstName.value,
        lastName: formState.inputs.shippingLastName.value,
        unit: formState.inputs.shippingUnit.value,
        city: formState.inputs.shippingCity.value,
        country: formState.inputs.shippingCountry.value,
        postal: formState.inputs.shippingPostal.value,
        province: formState.inputs.shippingProvince.value,
        street: formState.inputs.shippingStreet.value,
        phone: formState.inputs.shippingPhone.value,
        vat: formState.inputs.shippingVat.value,
      }
    }

    try {
      return updateAndConfirmGiftShippingAddress({
        variables: {
          input: {
            orderId:
              getGiftOrderToConfirmAddressData.GetGiftOrderToConfirmAddress
                .orderId,
            token: formState.inputs.giftToken.value,
            email: formState.inputs.giftEmail.value,
            shippingAddressId: selectedAddress.id,
            ...addressData,
          },
        },
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (updateAndConfirmGiftShippingAddressData) {
      setSuccess(true)
    }
  }, [updateAndConfirmGiftShippingAddressData])

  return (
    <Wrapper>
      {updateAndConfirmGiftShippingAddressLoading ? (
        <Paper className={classes.root} elevation={10}>
          <CircularProgress />
        </Paper>
      ) : success ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography component="h3" variant="h6">
            Success! You will receive details by email shortly.
          </Typography>
        </div>
      ) : (
        <>
          {shippingAddress && (
            <Paper className={classes.root} elevation={10}>
              <AddressWrapper>
                <form>
                  {isEditAddressMode || forceEditAddressMode ? (
                    <Grid container spacing={2}>
                      <>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <FormInput
                              fieldId="gift-email"
                              fieldName="giftEmail"
                              inputs={formState.inputs}
                              inputHandler={formActions.inputHandler}
                              // disabled={isDisabled}
                            />
                          </FormControl>
                        </Grid>
                        <Address
                          type="shipping"
                          address={{
                            firstName: selectedAddress.firstName,
                            lastName: selectedAddress.lastName,
                            unit: selectedAddress.unit,
                            city: selectedAddress.city,
                            country: selectedAddress.country,
                            postal: selectedAddress.postal,
                            province: selectedAddress.province,
                            street: selectedAddress.street,
                            phone: selectedAddress.phone,
                            vat: selectedAddress.vat,
                          }}
                        />
                      </>
                    </Grid>
                  ) : (
                    <>
                      <Typography component="h3" variant="h6">
                        Shipping Address
                      </Typography>

                      <NameText>
                        {shippingAddress.firstName} {shippingAddress.lastName}
                      </NameText>

                      <p>{formState.inputs.giftEmail.value}</p>

                      <p>
                        {shippingAddress.street}
                        {shippingAddress.unit
                          ? `, unit ${shippingAddress.unit}`
                          : ""}
                      </p>
                      <p>
                        {shippingAddress.city},{" "}
                        {shippingAddress.province
                          ? `${shippingAddress.province}, `
                          : ""}{" "}
                        {shippingAddress.country}
                      </p>
                      <p>{shippingAddress.postal}</p>
                      <p>Phone: {shippingAddress.phone}</p>
                    </>
                  )}
                  <ButtonsWrapper>
                    {!forceEditAddressMode && (
                      <Button
                        sectionId="add_shipping_dialog"
                        id={"change_address"}
                        type="secondaryOutline"
                        // disabled={createLoading}
                        title={isEditAddressMode ? "Cancel" : "Edit"}
                        onClick={() => {
                          setisEditAddressMode(!isEditAddressMode)
                        }}
                      />
                    )}

                    <Button
                      sectionId="add_shipping_dialog"
                      id="confirm"
                      type="primary"
                      title="Confirm"
                      onClick={handleConfirm}
                    />
                  </ButtonsWrapper>
                  {errorMsg && <p className="error">{errorMsg}</p>}
                </form>
              </AddressWrapper>
            </Paper>
          )}
        </>
      )}
    </Wrapper>
  )
}

export default ConfirmGiftAddress
