import React, { useEffect } from "react"
import Grid from "@material-ui/core/Grid"
import { FormInput } from "../ui/Input"
import { FormControl } from "@material-ui/core"
import { FormContextType, useForm } from "../../contexts/formContext"
import { ApolloError, QueryLazyOptions } from "@apollo/client"
import { Button } from "../ui/Button"
import styled from "styled-components"
import { shippingFields } from "../../checkout/shared/checkoutFormFields"

interface Props {
  getGiftOrderToConfirmAddress: (
    options?: QueryLazyOptions<Record<string, any>> | undefined
  ) => void
  getGiftOrderToConfirmAddressError: ApolloError | undefined
  getGiftOrderToConfirmAddressLoading: boolean
}

const Container = styled.div`
  padding-top: 1rem;
`

const ErrorMessage = styled.p`
  color: red;
`

const ConfirmGiftInput = ({
  getGiftOrderToConfirmAddress,
  getGiftOrderToConfirmAddressError,
  getGiftOrderToConfirmAddressLoading,
}: Props) => {
  const { formState, formActions } = useForm() as FormContextType

  const handleSubmit = () => {
    if (!formActions.isValid()) return

    getGiftOrderToConfirmAddress({
      variables: {
        input: {
          email: formState.inputs.giftEmail.value,
          token: formState.inputs.giftToken.value,
        },
      },
    })
  }

  useEffect(() => {
    formActions.setSkipValidationFunction((field: string): boolean => {
      return [...shippingFields].includes(field)
    })
  }, [])

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormInput
              fieldId="confirm-gift-email"
              fieldName="giftEmail"
              inputs={formState.inputs}
              inputHandler={formActions.inputHandler}
              // disabled={isOrderLoaded}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormInput
              fieldId="confirm-gift-token"
              fieldName="giftToken"
              inputs={formState.inputs}
              inputHandler={formActions.inputHandler}
              // disabled={isOrderLoaded}
            />
          </FormControl>
        </Grid>

        {getGiftOrderToConfirmAddressError && (
          <Grid item xs={12}>
            <ErrorMessage>
              We couldn't find a gift associated with that email and code
            </ErrorMessage>
          </Grid>
        )}

        <Grid item xs={12}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              type="primary"
              onClick={handleSubmit}
              id="get-gift-order"
              sectionId="confirm-gift-address"
              title="Claim My Gift"
              disabled={getGiftOrderToConfirmAddressLoading}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}

export default ConfirmGiftInput
