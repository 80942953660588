import React, { useEffect } from "react"
import { PageProps, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { CircularProgress } from "@material-ui/core"
import Container from "@material-ui/core/Container"
import CssBaseline from "@material-ui/core/CssBaseline"
import { makeStyles } from "@material-ui/core/styles"
import { FormContextProvider } from "../contexts/formContext"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { useLazyApi } from "../hooks/useLazyApi"
import {
  confirmGiftInput,
  shippingFormInputs,
} from "../checkout/shared/checkoutFormFields"
import { useLogout } from "../hooks/useLogout"
import ConfirmGiftAddressForm from "../components/user/ConfirmGiftInput"
import ConfirmGiftAddress from "../components/user/ConfirmGiftAddress"
import { UserAddress } from "../ts/interfaces"
import SimpleContent, {
  ContentSection,
} from "../components/sections/SimpleContent"

export interface GetGiftOrderToConfirmAddressData {
  GetGiftOrderToConfirmAddress: {
    orderId: string
    shippingAddress: UserAddress[]
    giftEmail: string
    giftFirstName: string
    giftLastName: string
  }
}

const LoaderWrapper = styled.div`
  margin-top: 24px;
`

const useStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}))

const RedeemPage: React.FC<PageProps> = ({}) => {
  const classes = useStyles()

  const [
    getGiftOrderToConfirmAddress,
    {
      loading: getGiftOrderToConfirmAddressLoading,
      error: getGiftOrderToConfirmAddressError,
      data: getGiftOrderToConfirmAddressData,
      errMsg: getGiftOrderToConfirmAddressMsg,
    },
  ] = useLazyApi("billing", "GetGiftOrderToConfirmAddress")

  const logout = useLogout()

  useEffect(() => {
    logout()
  }, [])

  const { strapi } = useStaticQuery(
    graphql`
      query {
        strapi {
          gift {
            redeemContentTop {
              content
              align
              core {
                anchorTag
                title
                titleColor
                subtitle
                subtitleColor
                backgroundColor
                animate
                marginTop
                marginBottom
                underlineTitle
                titleAlign
                divider
                backgroundImage {
                  url
                  alternativeText
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: NONE
                        layout: FULL_WIDTH
                        quality: 80
                      )
                    }
                  }
                }
              }
              featuredImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            redeemContentBottom {
              content
              align
              core {
                anchorTag
                title
                titleColor
                subtitle
                subtitleColor
                backgroundColor
                animate
                marginTop
                marginBottom
                underlineTitle
                titleAlign
                divider
                backgroundImage {
                  url
                  alternativeText
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: NONE
                        layout: FULL_WIDTH
                        quality: 80
                      )
                    }
                  }
                }
              }
              featuredImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const {
    redeemContentTop,
    redeemContentBottom,
  }: {
    redeemContentTop: ContentSection
    redeemContentBottom: ContentSection
  } = strapi.gift

  return (
    <Layout hasHero={false} showFooter={true} showHeader={true}>
      <SEO title="Claim Your Gift" />
      <Container component="main">
        <CssBaseline />
        <div className={classes.paper}>
          <SimpleContent {...redeemContentTop} padding="3rem 1rem 0 1rem" />

          <Container component="main" maxWidth="sm">
            <FormContextProvider
              initialInputs={{
                ...confirmGiftInput,
                ...shippingFormInputs,
              }}
            >
              {!getGiftOrderToConfirmAddressData && (
                <ConfirmGiftAddressForm
                  getGiftOrderToConfirmAddress={getGiftOrderToConfirmAddress}
                  getGiftOrderToConfirmAddressError={
                    getGiftOrderToConfirmAddressError
                  }
                  getGiftOrderToConfirmAddressLoading={
                    getGiftOrderToConfirmAddressLoading
                  }
                />
              )}

              {getGiftOrderToConfirmAddressData && (
                <ConfirmGiftAddress
                  getGiftOrderToConfirmAddressData={
                    getGiftOrderToConfirmAddressData
                  }
                />
              )}
            </FormContextProvider>
          </Container>

          {getGiftOrderToConfirmAddressLoading && (
            <LoaderWrapper>
              <CircularProgress />
            </LoaderWrapper>
          )}
          {redeemContentBottom && (
            <SimpleContent {...redeemContentBottom} padding="0 1rem 0 1rem" />
          )}
        </div>
      </Container>
    </Layout>
  )
}

export default RedeemPage
